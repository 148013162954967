import React, { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

const load_app = () => new Promise<JSX.Element>((resolve, reject) => {
   const appImport = (
        // window.location.href.includes("/link/form") ? import("./FormDisplay")
      window.location.href.includes("/video/joinlink") ? import("./pages/VideoCall")
      : import("./App")
   )
   appImport
   .then(module => resolve(<module.default/>))
   .catch(reject)
 })

const AppRouter = () => {
  const [LoadedApp, setLoadedApp] = useState(<LinearProgress />)

  useEffect(() => {
    load_app()
    .then(setLoadedApp)
    .catch(console.error)
  }, [])

  return (
    <>{LoadedApp}</>
  )
}

export default AppRouter